import React from "react";
import styles from "./index.module.css";
import lapImage from "../../assets/lap.png";

const DemoSection = () => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.innerDiv}>
        <div className={styles.leftDiv}>
          <img className={styles.img} src={lapImage} alt="" />
        </div>
        <div className={styles.rightDiv}>
          <div className={styles.infoDiv}>
            <div className={styles.titleDiv}>
              Get a Inside look of Epinics Point of Sale
            </div>
            <img className={styles.imgMob} src={lapImage} alt="" />
            <div className={styles.descriptionDiv}>
              <p>
                Helping you sell in-store, online and on-the-go,{" "}
                <strong>Epinics</strong> is an all-in-one retail POS software
                that has been designed with today’s retailers in mind.
              </p>
              <p>
                Conduct and manage all aspects of your business with the one,
                easy to use system. <strong>Epinics</strong> gives you less to
                worry about and more time to spend growing your business.
              </p>
            </div>
            <div className={styles.demoDiv}>
              <a
                className={styles.link}
                href="https://www.youtube.com/"
                target="_blank"
              >
                <div className={styles.demoBtn}>Watch Demo</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoSection;
