import React from "react";
import styles from "./index.module.css";
import { PiSpeedometerLight } from "react-icons/pi";
import {
  CiCreditCard1,
  CiMedal,
  CiSettings,
  CiDatabase,
  CiShoppingCart,
} from "react-icons/ci";
import { PiSpeedometerThin } from "react-icons/pi";

const FeaturesSection = () => {
  const featuresCards = [
    {
      id: 1,
      icon: <CiCreditCard1 />,
      title: "Effortless Card",
      description:
        "With our point of sale system, card payments are seamless and straightforward. Enjoy the convenience of quick, secure transactions that allow your customers to pay with ease, enhancing their experience while streamlining your checkout process.",
    },
    {
      id: 2,
      icon: <CiMedal />,
      title: "Software Accuracy",
      description:
        "Our cloud-based point of sale system delivers precise and reliable performance, ensuring every transaction is recorded accurately. Benefit from real-time data processing and error-free reporting, giving you confidence in the integrity of your business operations.",
    },
    {
      id: 3,
      icon: <CiSettings />,
      title: "Customization",
      description:
        "Our point of sale system adapts to your business. Customize everything from the interface to specific features, ensuring that the system aligns perfectly with your operational needs and brand identity, providing a personalized experience for you and your customers.",
    },
    {
      id: 4,
      icon: <CiDatabase />,
      title: "Customer Data",
      description:
        "Easily collect and analyze customer data with our point of sale system. Gain valuable insights into purchasing patterns, allowing you to offer personalized suggestions and make data-driven decisions that enhance customer satisfaction and boost sales.",
    },
    {
      id: 5,
      icon: <CiShoppingCart />,
      title: "Seamless Checkout",
      description:
        "Our point of sale system ensures a smooth and efficient checkout process. Minimize wait times and enhance customer satisfaction with fast, hassle-free transactions that keep your business running effortlessly.",
    },
    {
      id: 6,
      icon: <PiSpeedometerLight />,
      title: "Online Dashboard",
      description:
        "Access your business data anytime, anywhere with our intuitive online dashboard. Monitor sales, track performance, and manage operations in real-time, all from a user-friendly interface that keeps you connected to your business’s critical metrics.",
    },
  ];

  return (
    <div className={styles.mainDiv}>
      <div className={styles.posFeaturesDiv}>POS FEATURES</div>
      <div className={styles.everyFeaturesDiv}>
        EVERY FEATURE AT YOUR FINGERTIPS
      </div>
      <div className={styles.cardsDiv}>
        {featuresCards.map(card => (
          <div className={styles.card}>
            <div className={styles.leftDiv}>
              <div className={styles.iconDiv}>{card.icon}</div>
            </div>
            <div className={styles.rightDiv}>
              <div className={styles.title}>{card.title}</div>
              <div className={styles.description}>{card.description}</div>
            </div>
          </div>
        ))}
      </div>
      <a
        className={styles.link}
        href="https://www.youtube.com/"
        target="_blank"
      >
        <div className={styles.exploreBtn}>Explore all features</div>
      </a>
    </div>
  );
};

export default FeaturesSection;
