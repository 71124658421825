import React from "react";
import styles from "./index.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  PiShirtFoldedLight,
  PiBookBold,
  PiGuitar,
  PiGraphicsCard,
} from "react-icons/pi";
import { PiArmchairLight } from "react-icons/pi";
import {
  PiDiamondsFourLight,
  PiCoffee,
  PiCoin,
  PiHammerBold,
} from "react-icons/pi";

import CustomDot from "./CustomDot";

const RetailsStoreSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 500 },
      items: 2,
      slidesToSlide: 1,
    },
    xmobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const card = [
    { id: 1, name: "CLOTHING STORE", icon: <PiShirtFoldedLight /> },
    { id: 2, name: "BOOK SHOP", icon: <PiBookBold /> },
    { id: 3, name: "FURNITURE STORE", icon: <PiArmchairLight /> },
    { id: 4, name: "HARDWARE STORE", icon: <PiHammerBold /> },
    { id: 5, name: "PHARMACY", icon: <PiCoin /> },
    { id: 6, name: "COFFEE SHOPS", icon: <PiCoffee /> },
    { id: 7, name: "INSTRUMENTS", icon: <PiGuitar /> },
    { id: 8, name: "ELECTRONIC SHOPS", icon: <PiGraphicsCard /> },
  ];
  return (
    <div className={styles.mainDiv}>
      <div className={styles.posFeaturesDiv}>RETAIL STORES</div>
      <div className={styles.everyFeaturesDiv}>
        PERFECT POS SOFTWARE FOR MOST RETAIL STORES
      </div>
      <div className={styles.parent}>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          arrows={false}
          autoPlaySpeed={1000}
          customDot={<CustomDot />}
          dotListClass={styles.customDotListStyles}
        >
          {card.map((item, index) => {
            return (
              <div className={styles.cardDiv} key={index}>
                <div className={styles.card}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.name}>{item.name}</div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default RetailsStoreSection;
