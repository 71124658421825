import React from "react";
import styles from "./index.module.css";
import logo from "../../assets/logo-main.png";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";

const Footer = () => {
  const socials = [
    { id: 1, icon: <FaInstagram />, link: "", name: "Instagram" },
    { id: 1, icon: <FaFacebookF />, link: "", name: "Facebook" },
    { id: 1, icon: <FaXTwitter />, link: "", name: "XTwitter" },
    { id: 1, icon: <FaLinkedinIn />, link: "", name: "LinkedIn" },
  ];
  return (
    <div className={styles.mainDiv}>
      <div className={styles.innerDiv}>
        <div className={styles.left}>
          <div className={styles.logoDiv}>
            <img className={styles.logo} src={logo} alt={logo.toString()} />
            <div className={styles.name}>EPINICS</div>
          </div>
          <div className={styles.description}>
            Ours Pos Software is the ultimate solution designed to transform
            your business operations into a streamlined and efficient
            powerhouse. With a focus on simplicity, versatility, and
            cutting-edge technology.
          </div>
          <div className={styles.socialLinks}>
            {socials.map((item, index) => (
              <a href={item.link} target="_blank" className={styles.a}>
                <div className={styles.social}>{item.icon}</div>
              </a>
            ))}
          </div>
        </div>
        <div className={styles.mid}>
          <div className={styles.quickLinks}>
            <div className={styles.title}>Quick Links</div>

            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Home</div>
            </a>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Features</div>
            </a>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Pricing</div>
            </a>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Blog</div>
            </a>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Contact</div>
            </a>
          </div>
          <div className={styles.supports}>
            <div className={styles.title}>Supports</div>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Faq's</div>
            </a>
            <a href="" className={styles.a}>
              <div className={styles.subTitle}>Articles</div>
            </a>
          </div>
        </div>
        {/* <div className={styles.right}>
          <div className={styles.newsletter}>
            <div className={styles.title}>Subscribe Newsletter</div>
            <div className={styles.subscribeForm}>
              <input
                type="email"
                placeholder="Your email address"
                className={styles.input}
              />
              <button className={styles.button}>
                Send <span className={styles.arrow}>↗</span>
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
