import React from "react";
import "./index.css";
import ButtonOutlined from "../../../../common/components/ButtonOutlined";
import img from "../../assets/pos-set.png";

import { motion, useScroll } from "framer-motion";

const TopContainer = () => {
  const { scrollYProgress } = useScroll();
  return (
    <div className="top-container">
      <motion.div style={{ scaleX: scrollYProgress }} />
      <div className="top-container-body">
        <div className="top-container-logo"></div>
        <div className="top-container-body-content">
          <div className="maximize">Maximize Sales With Our Advanced</div>
          <div className="erp">ERP SOFTWARE SOLUTIONS</div>
          <div className="img-div">
            <img className="pos-img" src={img} alt="" />
          </div>
          <div className="user">
            User-Friendly Point of Sale that allows you to give your customers
            live level of service they expect
          </div>
        </div>
        <div className="button-container">
          <a href="" target="_blank">
            <div className="free-trial">Free Trial</div>
          </a>
          <a href="" target="_blank">
            <div className="talk-to-sales">Talk To Sales</div>
          </a>
        </div>
        <div className="pos-set"></div>
      </div>
    </div>
  );
};

export default TopContainer;
