import React from "react";
import styles from "./index.module.css";

const CustomDot = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button
      className={`${styles.customDot} ${
        active ? styles.active : styles.inactive
      }`}
      onClick={() => onClick()}
    />
  );
};

export default CustomDot;
