import React from "react";
import styles from "./index.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsChatSquareQuote } from "react-icons/bs";
import cus1 from "../../assets/cus1.jpg";
import cus2 from "../../assets/cus2.jpg";
import cus3 from "../../assets/cus3.jpg";
import cus4 from "../../assets/cus4.jpg";
import CustomDot from "./CustomDot";
import { PiStarFill } from "react-icons/pi";
import { RiDoubleQuotesR } from "react-icons/ri";

const TestimonilasSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1500, min: 1000 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 1000, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    xmobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const card = [
    {
      id: 1,
      name: "Anil Wijerathna",
      post: "Thilaka Medical Stores, Dharga Town",
      rate: 4.2,
      comment:
        "Their service is amazing and customer care is perfect. Issues are resolved in no time, and the system is incredibly easy to use. We couldn’t be happier with the support and efficiency!",
      image: cus1,
    },
    {
      id: 2,
      name: "Edward Wolfe",
      post: "Customer",
      rate: 3.1,
      comment:
        "With Thrive's help, we were able to increase the functionality of our website dramatically while we were able to increase the.",
      image: cus2,
    },
    {
      id: 3,
      name: "Rodney Bryner",
      post: "Customer",
      rate: 4.7,
      comment:
        "With Thrive's help, we were able to increase the functionality of our website dramatically while we were able to increase the.",
      image: cus3,
    },
  ];

  return (
    <div className={styles.mainDiv}>
      <div className={styles.posFeaturesDiv}>Testimonials</div>
      <div className={styles.everyFeaturesDiv}>What our client's say</div>
      <div className={styles.parent}>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          arrows={false}
          autoPlaySpeed={3000}
          customDot={<CustomDot />}
          dotListClass={styles.customDotListStyles}
        >
          {card.map((item, index) => {
            const stars = Math.round(item.rate);
            const starsBack = 5;
            return (
              <div className={styles.cardDiv} key={index}>
                <div className={styles.card}>
                  <div className={styles.comment}>{item.comment}</div>
                  <div className={styles.bottomDiv}>
                    <div className={styles.left}>
                      <div className={styles.dpDiv}>
                        <img
                          className={styles.dp}
                          src={item.image}
                          alt={item.image.toString()}
                        />
                        <div className={styles.iconDiv}>
                          <RiDoubleQuotesR className={styles.icon} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.post}>{item.post}</div>
                      <div className={styles.starsBack}>
                        {[...Array(starsBack)].map((_, i) => (
                          <PiStarFill className={styles.starInactive} key={i} />
                        ))}
                      </div>
                      <div className={styles.starsFront}>
                        {[...Array(stars)].map((_, i) => (
                          <PiStarFill className={styles.starActive} key={i} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default TestimonilasSection;
