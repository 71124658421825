import React from "react";
import styles from "./index.module.css";
import phoneImage from "../../assets/banner-phone.png";

const UpgradeYourBusinessSection = () => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.banner}>
        <div className={styles.info}>
          <div className={styles.top}>
            <div className={styles.title}>
              Upgrade Your Business with Our Cutting-Edge POS Solutions
            </div>
            <div className={styles.mobImgDiv}>
              <img
                className={styles.mobImg}
                src={phoneImage}
                alt="banner-phone"
              />
            </div>
            <div className={styles.description}>
              Experience the future of transactions! Schedule a demo today and
              witness how our POS solution can revolutionize your business.
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.btnDiv}>
              <a
                className={styles.links}
                href="https://www.youtube.com/"
                target="_blank"
              >
                <div className={styles.trialBtn}>Start Free Trial</div>
              </a>
              <a
                className={styles.links}
                href="https://www.youtube.com/"
                target="_blank"
              >
                <div className={styles.purchaseBtn}>Purchase Now</div>
              </a>
            </div>
          </div>
        </div>
        <img className={styles.img} src={phoneImage} alt="banner-phone" />
      </div>
    </div>
  );
};

export default UpgradeYourBusinessSection;
