import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../../assets/logo-main.png";
import styles from "./index.module.css";

const TopNavigation = () => {
  const [showMobNav, setShowMobNav] = useState(false);

  return (
    <div className={styles.topNavContainer}>
      <div
        className={`${styles.mobNav} ${showMobNav ? styles.mobNavShow : ""}`}
      >
        <div className={styles.logoDiv}>
          <div className={styles.left}>
            <img className={styles.logo} src={logo} alt="logo" />
            <div className={styles.name}>EPINICS</div>
          </div>
          <div className={styles.right}>
            <IoClose onClick={() => setShowMobNav(false)} />
          </div>
        </div>
        {["HOME", "INFO", "FEATURES", "PRICING", "CONTACT", "FAQ"].map(
          (link, index) => (
            <a href={`#${link.toLowerCase()}`}>
              <div
                key={index}
                className={styles.mobNavLink}
                onClick={() => setShowMobNav(false)}
              >
                {link}
              </div>
            </a>
          )
        )}
      </div>
      <div className={styles.burgerIconDiv}>
        <RxHamburgerMenu
          className={styles.burgerIcon}
          onClick={() => setShowMobNav(!showMobNav)}
        />
      </div>
      <div className={styles.navLinkContainer}>
        {["HOME", "INFO", "FEATURES", "PRICING", "CONTACT", "FAQ"].map(
          (link, index) => (
            <a href={`#${link.toLowerCase()}`}>
              <div key={index} className={styles.navLink}>
                {link}
              </div>
            </a>
          )
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
