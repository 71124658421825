import React from "react";
import TopNavigation from "./TopNavigation";
import TopContainer from "./TopContainer";
import InfoSection from "./InfoSection";
import DemoSection from "./DemoSection";
import FeaturesSection from "./FeaturesSection";
import RetailsStoreSection from "./RetailsStoreSection";
import UpgradeYourBusinessSection from "./UpgradeYourBusinessSection";
import TestimonialsSection from "./TestimonialsSection";
import FaqSection from "./FaqSection";
import Footer from "./FooterSection";
import { motion, useScroll } from "framer-motion";
import "./index.css";

const Layout = () => {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <div className="container">
        <div id="home">
          <TopNavigation />
        </div>
        <TopContainer />
        <div id="info">
          <InfoSection />
        </div>
        <div id="">
          <DemoSection />
        </div>
        <div id="features">
          <FeaturesSection />{" "}
        </div>
        <RetailsStoreSection />
        <div id="pricing">
          <UpgradeYourBusinessSection />
        </div>

        <TestimonialsSection />
        <div id="faq">
          <FaqSection />
        </div>
        <div id="contact">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
