import React from "react";
import styles from "./index.module.css";
import { FiArrowUpRight } from "react-icons/fi";
import { CiCoinInsert } from "react-icons/ci";
import { CiSatellite1 } from "react-icons/ci";
import { CiApple, CiBadgeDollar } from "react-icons/ci";

import { CiShop } from "react-icons/ci";

const InfoSection = () => {
  const inforCards = [
    {
      id: 1,
      icon: <CiShop />,
      title: "For All Types Businesses",
      description:
        "Our cloud-based point of sale system is designed to cater to the unique needs of businesses across all industries. Whether you run a retail store, restaurant, or service-based business, our solution offers the flexibility and scalability to support your operations, streamline transactions, and enhance customer satisfaction.",
      link: "https://www.youtube.com/",
    },
    {
      id: 2,
      icon: <CiBadgeDollar />,
      title: "Cost Effective With Affordable Price",
      description:
        "Get the best value for your business with our point of sale system. We offer competitive pricing without compromising on quality, ensuring that you can access top-notch features and support at an affordable rate. Invest in a solution that saves you money while boosting your business efficiency.",
      link: "https://www.youtube.com/",
    },
    {
      id: 3,
      icon: <CiSatellite1 />,
      title: "Easy to Setup & No IT Knowledge Need",
      description:
        "Our cloud point of sale system is user-friendly and quick to set up, requiring no technical expertise. Start managing your business effortlessly with an intuitive interface that makes installation and operation a breeze, so you can focus on what you do best.",
      link: "https://www.youtube.com/",
    },
    {
      id: 4,
      icon: <CiApple />,
      title: "Modern & Attractive User Dashboard",
      description:
        "Experience the power of a sleek, modern dashboard designed for ease of use and visual appeal. Our cloud point of sale system features an intuitive interface that provides real-time insights and streamlined navigation, making it simple to manage your business with style.",
      link: "https://www.youtube.com/",
    },
  ];

  return (
    <div className={styles.infoSectionContainer}>
      <div className={styles.titleDiv}>
        <div className={styles.titleTop}>
          Sell Intelligently Our Brand-New Retail Point of Sale
        </div>
        <div className={styles.titleLow}>
          <span className={styles.premiumText}>#Premium</span>{" "}
          <span>Software</span>
        </div>
      </div>
      <div className={styles.infoCardsDiv}>
        {inforCards.map(card => (
          <div className={styles.infoCard}>
            <div className={styles.topInnerInfoCardDiv}>
              <div className={styles.cardIcon}>{card.icon}</div>
              <div className={styles.cardTitle}>{card.title}</div>
              <div className={styles.cardDescription}>{card.description}</div>
            </div>
            <a className={styles.link} href={card.link} target="_blank">
              {" "}
              <div className={styles.cardLink}>
                Learn More <FiArrowUpRight className={styles.cardLinkIcon} />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoSection;
