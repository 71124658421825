import React, { useState } from "react";
import styles from "./index.module.css";
import screenImage from "../../assets/touch-screen.png";
import { HiPlus, HiMinus } from "react-icons/hi";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

const FaqSection = () => {
  const [selectedIndex, setelectedIndex] = useState(0);

  const freq_ask_qs = [
    {
      id: "1",
      question: "What is a point of sale?",
      answer:
        "A Point of Sale (POS) system is a combination of hardware and software that allows businesses to process transactions, manage sales, and track inventory. It typically includes a register or terminal, a payment processor, and a user interface for recording sales and generating reports. POS systems streamline the checkout process, improve accuracy, and provide valuable insights into business operations.",
    },
    {
      id: "2",
      question: "Is your POS system compatible with my existing hardware?",
      answer:
        "Our POS system is designed to be compatible with a wide range of hardware. Whether you have existing printers, barcode scanners, or cash drawers, we can help integrate them with our system. For any specific compatibility questions, please contact our support team.",
    },
    {
      id: "3",
      question: "Can I customize the POS system to fit my business needs?",
      answer:
        "Our POS system is designed with a comprehensive range of features to meet the needs of most businesses. We’ve developed many functionalities into the system, ensuring it covers a wide array of requirements. However, we also highly value our clients' feedback and recommendations. We consider their suggestions for future updates, which helps us continually enhance the system and make it even more versatile for all users. If you have specific needs, please reach out to us, and we'll be happy to discuss how our system can best support your business.",
    },
    {
      id: "4",
      question: "How secure is your cloud-based POS system?",
      answer:
        "Our cloud-based POS system uses advanced encryption and security protocols to protect your data. We implement regular security updates and backups to ensure that your information remains safe and secure.",
    },
    {
      id: "5",
      question: "Can I access my POS data from multiple locations?",
      answer:
        "Yes, our cloud-based system allows you to access your data from any location with an internet connection. This flexibility lets you manage and monitor your business from anywhere, whether you're in the office or on the go.",
    },
    {
      id: "6",
      question: "How do I get started with your POS system?",
      answer:
        "Getting started with our POS system is simple: first, visit our onboarding site to create your root business account. Next, download the software from the site. Finally, follow the installation instructions to set it up and start using it. If you need any assistance, our support team is available to guide you through the process.",
    },
    {
      id: "5",
      question: "Are there any additional fees for using the POS system?",
      answer:
        "No, there are no hidden fees. We charge only the monthly package price, with no additional costs or surprises. Our pricing is transparent, so you know exactly what to expect.",
    },
    {
      id: "5",
      question: "Can I access my POS data from multiple locations?",
      answer:
        "Yes, our cloud-based system allows you to access your data from any location with an internet connection. This flexibility lets you manage and monitor your business from anywhere, whether you're in the office or on the go.",
    },
  ];

  const handleOnClick = i => {
    if (selectedIndex === i) {
      return setelectedIndex(null);
    }
    setelectedIndex(i);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.posFeaturesDiv}>HAVE ANY QUESTIONS?</div>
      <div className={styles.everyFeaturesDiv}>FREQUENTLY ASKED QUESTIONS</div>
      <div className={styles.contentDiv}>
        <div className={styles.leftDiv}>
          <img className={styles.img} src={screenImage} alt="" />
        </div>
        <div className={styles.rightDiv}>
          {freq_ask_qs.map((data, index) => (
            <div className={styles.nkn} key={index}>
              <div className={styles.card}>
                <div
                  className={styles.upperDiv}
                  onClick={() => {
                    handleOnClick(index);
                  }}
                >
                  <div className={styles.title}>
                    <span className={styles.q}>Q{index + 1}.</span>{" "}
                    {data.question}
                  </div>
                  <div className={styles.icon}>
                    {index === selectedIndex ? (
                      <IoIosArrowDropupCircle />
                    ) : (
                      <IoIosArrowDropdownCircle />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  index === selectedIndex
                    ? styles.paragraph
                    : styles.noParagraph
                }
              >
                <div dangerouslySetInnerHTML={{ __html: data.answer }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
